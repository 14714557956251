export function autoCorrelate(arr: number[]): number[] {
  var output: number[] = [];
  for (var i = 0; i < arr.length; i++) {
    var sum = 0;
    for (var k = 0; k < arr.length - i; k++) {
      sum += arr[k] * arr[k + i];
    }
    output.push(sum);
  }
  return output;
}

export function getPeriodFromAutocorrelateOutput(arr: number[]): number {
  // only count peaks of the signal, ignoring the first peak which is 0 offset.
  // only count as a peak if you observe the slope changing from positive to negative.
  var highestPeak = 0;
  var highestPeakIdx = 0;
  var lastVal = 0;
  var lastSlope = 0;
  for (var i = 0; i < arr.length; i++) {
    var slope = arr[i] - lastVal;

    if (i == 0) slope = 0;

    if (i != 0 && slope < 0 && lastSlope > 0) {
      var thisPeakIdx = i - 1;
      var thisPeak = arr[thisPeakIdx];

      if (thisPeak > highestPeak) {
        highestPeakIdx = thisPeakIdx;
        highestPeak = thisPeak;
      }
    }
    lastVal = arr[i];
    lastSlope = slope;
  }

  // dont try to approximate true peak if it's first or last elem
  if (highestPeakIdx == 0 || highestPeakIdx == arr.length - 1) return highestPeakIdx;

  // now, approximate the 3 points around the peak as a parabola, and find the vertex
  // otherwise the reported freqency jitters a lot based on sample quantization
  var points = arr.slice(highestPeakIdx - 1, highestPeakIdx + 2);
  var paraCoefs = threePointsToParabola(
    highestPeakIdx - 1, points[0],
    highestPeakIdx + 0, points[1],
    highestPeakIdx + 1, points[2]
  );
  var vertex = vertexOfParabola(paraCoefs.a, paraCoefs.b, paraCoefs.c);
  return vertex.x; 
}

// https://stackoverflow.com/questions/717762/how-to-calculate-the-vertex-of-a-parabola-given-three-points
export function threePointsToParabola(x1: number, y1: number, x2: number, y2: number, x3: number, y3: number) {
  var denom = (x1 - x2) * (x1 - x3) * (x2 - x3);
  var a = (x3 * (y2 - y1) + x2 * (y1 - y3) + x1 * (y3 - y2)) / denom;
  var b = (x3 * x3 * (y1 - y2) + x2 * x2 * (y3 - y1) + x1 * x1 * (y2 - y3)) / denom;
  var c = (x2 * x3 * (x2 - x3) * y1 + x3 * x1 * (x3 - x1) * y2 + x1 * x2 * (x1 - x2) * y3) / denom;
  return { a, b, c };
}

// https://www.mathwarehouse.com/geometry/parabola/vertex-of-a-parabola.php
export function vertexOfParabola(a: number, b: number, c: number) {
  var x = -b / (2 * a);
  var y = a * x * x + b * x + c;
  return { x, y };
}