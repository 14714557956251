import { AudioConsumer } from "./AudioSource";

export class SignalMeter implements AudioConsumer {
  reading: number = 0;
  onUpdate?: () => void;

  writeAudio(buff: Float32Array, sampleRate: number): void {
    var sumSquared = 0;
    for (var i = 0; i < buff.length; i++) {
      sumSquared += buff[i] * buff[i];
    }
    var rms = Math.sqrt(sumSquared /= buff.length);

    this.reading = rms;
    this.onUpdate && this.onUpdate();
  }
}