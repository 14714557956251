import { autoCorrelate, getPeriodFromAutocorrelateOutput } from "../util/math";
import { AudioConsumer } from "./AudioSource";


export class TunerBasic implements AudioConsumer {
  period: number = 0;
  sampleRate: number = 0;
  onUpdate?: () => void;
  accum = 0;
  ac: number[] = [];

  writeAudio(buff: Float32Array, sampleRate: number): void {
    this.sampleRate = sampleRate;
    //@ts-ignore
    this.ac = autoCorrelate(buff);
    this.period = getPeriodFromAutocorrelateOutput(this.ac);
    this.onUpdate && this.onUpdate();
  }

  get hz() {
    return this.sampleRate / this.period;
  }
}

