import React from "react";
import styles from "./Tuner.module.css";

export function LED({ baseColor }: { baseColor: { r: number, g: number, b: number } }) {
  return (
    <div
      className={styles.led}
      style={{
        background: `radial-gradient(circle, rgba(${baseColor.r*2},${baseColor.g*2},${baseColor.b*2},1.0) 50%, rgba(${baseColor.r},${baseColor.g},${baseColor.b},1.0) 100%)`,
        boxShadow: `0 0 10px rgba(${baseColor.r},${baseColor.g},${baseColor.b},1.0)`,
      }}
    />
  )
}