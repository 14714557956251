import React from "react";
import styles from "./Tuner.module.css";
import { noteLabels } from "../domain/MusicTheory";

export interface NoteGridProps {
  hz: number;
  width: number;
  height: number;
  userReferenceA4: number;
}

export interface NoteGridSectionProps {
  width: number;
  height: number;
}

export function NoteGridAtom({ width, height }: NoteGridSectionProps) {
  var notes = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  function xFunc(n: number) {
    return n / (notes.length - 1) * width;
  }

  return (
    <svg width={width} height={height}>
      {notes.map((note, i) => {
        var y = 60;

        return (
          <g key={note}>
            <text x={xFunc(note)} y={y + 30} fill="black" textAnchor="middle" fontSize="24" fontFamily="Roboto Mono">{noteLabels[i % noteLabels.length]}</text>

            <line x1={xFunc(note)} y1={0} x2={xFunc(note)} y2={y} style={{ stroke: "rgb(0, 0, 0)", strokeWidth: 2 }} />

            {[0, 1, 2, 3].map((n, i) =>
              <line key={n} x1={xFunc(note + i / 4)} y1={0} x2={xFunc(note + i / 4)} y2={y / 2} style={{ stroke: "rgb(0, 0, 0, 0.2)", strokeWidth: 2 }} />
            )}
          </g>
        )
      })}
    </svg>
  )
}

export function NoteGridSlider({ width, height, hz, userReferenceA4 }: NoteGridProps) {
  function style() {
    var standardA4 = 440;
    var detune = standardA4 / userReferenceA4;
    var midC = 261.626;
    var relativeToMidC = hz / midC * detune;
    var offset = Math.log2(relativeToMidC);
    offset += 4;// lowest note possible to displace will be c-1, way lower than useful
    return {
      left: ((-1 - offset) * width) + "px", // always align on the middle of the three octaves
      transition: "left 0.1s"
    }
  }

  return (
    <div className={styles.sliderContainer} style={{ height: height }}>
      <div className={styles.sliderCenterer}>
        <div className={styles.tickMark}></div>
        <div className={styles.slider} style={style()}>
          <NoteGrid width={width} height={height} />
          <NoteGrid width={width} height={height} />
          <NoteGrid width={width} height={height} />
          <NoteGrid width={width} height={height} />
          <NoteGrid width={width} height={height} />
          <NoteGrid width={width} height={height} />
          <NoteGrid width={width} height={height} />
          <NoteGrid width={width} height={height} />
          <NoteGrid width={width} height={height} />
          <NoteGrid width={width} height={height} />
          <NoteGrid width={width} height={height} />
          <NoteGrid width={width} height={height} />
        </div>
      </div>
    </div>
  )
}

export const NoteGrid = React.memo(NoteGridAtom);