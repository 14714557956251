declare global { interface Window { webkitAudioContext: AudioContextConstructor } };

var AudioContext = window.AudioContext || window.webkitAudioContext;

export interface AudioConsumer {
  writeAudio(samples: Float32Array, sampleRate: number): void;
}


export class AudioSource {
  stream?: MediaStream;
  context?: AudioContext;
  source?: MediaStreamAudioSourceNode;
  processor?: ScriptProcessorNode;
  listeners: Set<AudioConsumer> = new Set();
  error: boolean = false;

  on(listener: AudioConsumer) {
    this.listeners.add(listener);
  }

  off(listener: AudioConsumer) {
    this.listeners.delete(listener);
  }

  initAudio(deviceId: string) {
    navigator.mediaDevices
      .getUserMedia({ audio: { deviceId: deviceId }, video: false })
      .then(this.handleSuccess.bind(this))
      .catch(() => {}); // TODO we failed, put the app in a "sorry we failed" state
  }

  endCurrentStream() {
    // TODO: is this enough? or do we need to take down the audio context/source/processor as well
    console.log("ending existing audio stream");
    if (this.stream) this.stream.getTracks().forEach(t => t.stop());
    if (this.context) this.context.close();
    if (this.processor) this.processor.disconnect();
    if (this.source) this.source.disconnect();

    this.stream = this.context = this.processor = this.source = undefined;
  }

  handleSuccess(stream: MediaStream) {
    // try {

    this.stream = stream;
    this.context = new AudioContext();
    this.source = this.context.createMediaStreamSource(stream);
    var bufferSize = 4096;

    // var isMobileIsh = /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Kindle|NetFront|Silk-Accelerated|(hpw|web)OS|Fennec|Minimo|Opera M(obi|ini)|Blazer|Dolfin|Dolphin|Skyfire|Zune/.test(navigator.userAgent);

    this.processor = this.context.createScriptProcessor(bufferSize, 1, 1);

    this.source.connect(this.processor);
    this.processor.connect(this.context.destination);

    this.processor.onaudioprocess = (e) => {
      // we downsample the audio because we don't care about really high frequency content,
      // and this is an N^2 operation that melts mobile device battery at a hilarious rate
      var buff = e.inputBuffer.getChannelData(0);
      var ratio = 4;
      var downsampled = new Float32Array(buff.length / ratio);
      for (var i = 0; i < buff.length; i++) {
        downsampled[Math.floor(i / ratio)] = buff[i] / ratio;
      }
      this.listeners.forEach(listener => listener.writeAudio(downsampled, e.inputBuffer.sampleRate / ratio));
    }

    // to debug mobile devices when i'm too lazy to hook up real remote debugging
    // } catch (e) {
    //   document.write(e);
    // }

  }
}