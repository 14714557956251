import React, { useEffect, useState } from "react";
import styles from "./Tuner.module.css";
import { TunerSettings } from "../domain/TunerSettings";

export interface InputSelectorProps {
  onUserSelectedInput: (id: string) => void,
  settings: TunerSettings,
  applySettings: (settings: TunerSettings) => void
}

export function TunerSettingsUI({ settings, applySettings, onUserSelectedInput }: InputSelectorProps) {
  var [inputs, setInputs] = useState<MediaDeviceInfo[]>([]);

  useEffect(() => {
    navigator.mediaDevices.enumerateDevices()
      .then(inputsFound)
      .catch(inputError);
  }, []);

  function inputsFound(allInfo: MediaDeviceInfo[]) {
    var availableInputs = allInfo.filter(info => info.kind == "audioinput");
    if (availableInputs.length > 0) {
      setInputs(availableInputs);
      onUserSelectedInput(availableInputs[0].deviceId);
    } else {
      // hacky workaround for things like iOS where this is an empty list but "default" does indeed work
      setInputs([{ deviceId: "default" } as MediaDeviceInfo]);
    }
  }

  function inputError(e: any) {
    setInputs([]);
  }

  return (
    <div className={styles.settings}>
      {/* <p>Audio inputs: ({inputs.length} available)</p> */}
      <select onChange={(e) => onUserSelectedInput(e.currentTarget.value)}>
        {inputs.map(info => <option key={info.deviceId} value={info.deviceId}>{info.label}</option>)}
      </select>

      <input type="number" value={settings.referencePitch} onChange={(e) => applySettings({ ...settings, referencePitch: +e.currentTarget.value })} />

      {/* <div>
        <label>
          <input type="checkbox" checked={settings.power} onChange={(e) => applySettings({ ...settings, power: e.currentTarget.checked })} /> Power
        </label>
      </div> */}
      {/* <div>
        <label>
          <input type="checkbox" checked={settings.lowPass} onChange={(e) => applySettings({ ...settings, lowPass: e.currentTarget.checked })} /> Low pass filter
        </label>
      </div> */}
    </div>
  )
}