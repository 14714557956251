import React, { useEffect, useState } from "react";
import { AudioSource } from "../domain/AudioSource";
import { SignalMeter } from "../domain/SignalMeter";
import { TunerBasic } from "../domain/TunerBasic";
import { TunerSettings } from "../domain/TunerSettings";
import { LED } from "./LED";
import styles from "./Tuner.module.css";
import { TunerBasicUI } from "./TunerBasicUI";
import { TunerSettingsUI } from "./TunerSettingsUI";

// this is a component that encapsulates and coordinates all the audio inputs, tuner variants, etc.
// try to keep as much state and plumbing of domain objects in here.
export function TunerManager() {
  var [inputDeviceId, setInputDeviceId] = useState<string>("default");
  var [settings, setSettings] = useState<TunerSettings>(() => ({ referencePitch: 440, power: true, lowPass: false }));
  var [signalReading, setSignalReading] = useState(0);
  var [tunerBasicHz, setTunerBasicHz] = useState(0);

  useEffect(() => {
    console.info("Using input deviceId: " + inputDeviceId);

    var astream = new AudioSource();
    astream.initAudio(inputDeviceId);

    var meter = new SignalMeter();
    astream.on(meter);
    meter.onUpdate = () => setSignalReading(meter.reading);

    var tuner = new TunerBasic();
    astream.on(tuner);
    tuner.onUpdate = () => setTunerBasicHz(tuner.hz);

    return () => astream.endCurrentStream();
  }, [inputDeviceId || "default"]);

  return (
    <div className={styles.tuner}>
      <div className={styles.screw} style={{ top: 10, left: 10, transform: "rotate(45deg)" }} />
      <div className={styles.screw} style={{ top: 10, right: 10, transform: "rotate(12deg)" }} />
      <div className={styles.screw} style={{ bottom: 10, left: 10, transform: "rotate(30deg)" }} />
      <div className={styles.screw} style={{ bottom: 10, right: 10, transform: "rotate(-8deg)" }} />
      <div className={styles.tunerInner}>
        <h1>Chromatic Tuner</h1>
        {/* <LED baseColor={{ r: signalGain * 255, g: signalGain * 100, b: signalGain * 30 }} /> */}
        {/* <LED reading={1} baseColor={{ r: 150, g: 30, b: 30 }} /> */}
        <TunerBasicUI hz={tunerBasicHz || 440} settings={settings} />
        <TunerSettingsUI onUserSelectedInput={setInputDeviceId} settings={settings} applySettings={setSettings} />
      </div>
    </div>
  )
}