import React, { useEffect, useState } from "react";
import { TunerSettings } from "../domain/TunerSettings";
import { NoteGridSlider } from "./NoteGrid";
import styles from "./Tuner.module.css";
import { getNoteInfoForHz, noteLabels } from "../domain/MusicTheory";
import { LED } from "./LED";

interface TunerBasicProps {
  hz: number,
  settings: TunerSettings
}

export function TunerBasicUI({ hz, settings }: TunerBasicProps) {
  var currentNote = getNoteInfoForHz(hz, settings.referencePitch);
  var targetNote = Math.round(currentNote);
  var error = currentNote - targetNote;
  var targetNoteLabel = noteLabels[(targetNote % 12 + 12) % 12];
  var octave = Math.floor(targetNote / 12 + 4);

  var bigNoteText = hz > 0 ? targetNoteLabel + octave : null;

  var threshold = 0.1;
  var isLow = error < -threshold;
  var isHigh = error > threshold;
  var isWayLow = error < -threshold*2;
  var isWayHigh = error > threshold*2;
  var isCloseEnough = Math.abs(error) <= threshold;

  return (
    <div>
      <div className={styles.currentNote}>
        <div className={styles.note}>{bigNoteText ? bigNoteText : <>---</>}</div>
        <div className={styles.cents}>
          {!isNaN(error)
            ? (error > 0 ? "+" : "") + (error * 100).toFixed(1) + " cents"
            : <>---</>
          }

        </div>
        <div className={styles.hz}>{hz > 0 ? hz.toFixed(2) + "hz" : "---"}</div>
      </div>
      <LED baseColor={isWayLow ? { r: 255, g: 50, b: 10 } : { r: 30, g: 0, b: 0 }} />
      <LED baseColor={isLow ? { r: 255, g: 50, b: 10 } : { r: 30, g: 0, b: 0 }} />
      <LED baseColor={isCloseEnough ? { r: 20, g: 200, b: 255 } : { r: 0, g: 30, b: 60 }} />
      <LED baseColor={isHigh ? { r: 255, g: 50, b: 10 } : { r: 30, g: 0, b: 0 }} />
      <LED baseColor={isWayHigh ? { r: 255, g: 50, b: 10 } : { r: 30, g: 0, b: 0 }} />
      <NoteGridSlider hz={hz} userReferenceA4={settings.referencePitch} width={12 * 100} height={100} />
    </div>
  )
}